"use client"
import styled from "@emotion/styled"
import { Button, Menu, MenuItem } from "@mui/material"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { useRef, useState } from "react"
import { trackTopBarLinkClicked } from "reusable/ChargeGTM"
import { NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { extractMenuProperties } from "utils"
import { getNonUmbracoURL } from "utils/utils"

const StyledLink = styled("li", {
  shouldForwardProp: (prop) =>
    prop !== "selected" && prop !== "color" && prop !== "isLast",
}) <{
  selected?: boolean
  color: string
  isLast?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color};
  text-decoration: none;
  transition: 300ms all ease;
  padding: 0 8px;
    ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px;
  }
  border-bottom: none;
  box-shadow: ${(props) =>
    props.selected ? "0px 8px 0px #fcbb00 inset" : "none"};
  :hover {
    box-shadow: 0px 8px 0px #fcbb00 inset;
  }
  li{
    background-color: #f5f5f5;
  }
`

const StyledMenuItem = styled(MenuItem) <{ component: string }>`
  padding: 12px 32px ;
`

const StyledMenu = styled(Menu)`
  ${(props) => props.theme.breakpoints.up("md")} {
  margin-top: 10px;
  }

`

const StyledDiv = styled.li<{
  selected?: boolean
  color: string
  isLast?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color};

  text-decoration: none;
  button,
  a {
    padding: 0 8px;
    ${(props) => props.theme.breakpoints.up("lg")} {
      padding: 0 20px;
      padding: 0 min(8px, 0.7vw);
    }
    :hover {
      background: transparent;
    }
  }

  p {
    font-weight: ${(props) => (props.selected ? "500" : "400")};
  }
  border-bottom: ${(props) => (props.isLast ? "none" : " 1px dashed #a8acb4")};
  ${(props) => props.theme.breakpoints.up("md")} {
    transition: 300ms all ease;
    border-bottom: none;
    box-shadow: ${(props) =>
    props.selected ? "0px 8px 0px #fcbb00 inset" : "none"};
    :hover {
      box-shadow: 0px 8px 0px #fcbb00 inset;
    }
  }
`

const StyledButton = styled(Button)`
border-radius:0;
:focus-visible{
    outline:2px solid blue;
  }
`

const ExtensionIndicator = styled.div`
  position: absolute;
  /* top: 50%; */
  bottom:0;
  left: 0; /* Adjust as needed */
  transform: translateY(-50%) rotate(90deg);
  opacity: 0;
  transition: opacity 0.3s ease;

  ${StyledButton}:focus-visible + & {
    opacity: 1;
  }
`;

const OutlineLink = styled(Link)`
   :focus-visible{
    outline:2px solid blue;
  }
`

const StyledLi = styled.li`
  padding:0;
  margin:0;
  position:static;
`

const isPathnameInSubCategories = (pathname: string, subCategories: any[]) => {
  return subCategories.some((subCategory) => {
    const { url } = extractMenuProperties(subCategory)
    return `${pathname}/`.includes(getNonUmbracoURL(url))
  })
}

type Props = {
  href: string
  selected: boolean
  title: string
  color?: string
  onClick?: () => void
  isLast?: boolean
  subCategories?: any[]
  blank?: boolean
  lube?: boolean
  index: number
}

function DesktopNavlink({
  href,
  selected,
  title,
  color = sharedColors.black,
  onClick,
  isLast,
  subCategories,
  blank,
  index: navIndex
}: Props) {
  const pathname = usePathname()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const firstMenuItemRef = useRef<HTMLLIElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    trackTopBarLinkClicked(title)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (subCategories?.length) {
    return (
      <StyledDiv
        color={color}
        isLast={isLast}
        selected={isPathnameInSubCategories(pathname, subCategories)}
      >
        <StyledButton
          id={`basic-button-${navIndex}`}
          aria-expanded={open ? "true" : "false"}
          onClick={handleClick}
          ref={buttonRef}
          disableRipple={true}
          onKeyDown={(e) => {
            if (e.key === "Tab" && !e.shiftKey && open) {
              e.preventDefault()
              if (firstMenuItemRef.current) {
                firstMenuItemRef.current.focus()
              }
            }
            if ((e.key === "Escape" || e.key === "Tab" && e.shiftKey) && open) {
              handleClose()
            }
          }}
        >
          <NewText
            component={"p"}
            desktopFontSize={14}
            desktopFontWeight={400}
            largeDesktopFontSize={18}
            largeDesktopLineHeight="20px"
          >
            {title}
          </NewText>
        </StyledButton>
        <ExtensionIndicator>➤</ExtensionIndicator>
        <StyledMenu
          disableScrollLock={true}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          id={`basic-menu-${navIndex}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": `basic-button-${navIndex}`,
            style: {
              minWidth: buttonRef.current ? buttonRef.current.offsetWidth : 200,
            }, // Use 200px as a fallback width}
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {subCategories.map((item, index) => {
            const {
              title: subTitle,
              url,
              blank: blankChild,
            } = extractMenuProperties(item)
            const contact = subTitle === "צור קשר"
            const isBlank = !contact && blankChild
            return (
              <StyledLi key={index.toString()}>
                <Link href={getNonUmbracoURL(url)} target={isBlank ? "_blank" : undefined} legacyBehavior passHref>
                  <StyledMenuItem
                    component={"a"}
                    key={index.toString()}
                    tabIndex={0}
                    ref={index === 0 ? firstMenuItemRef : null}
                    onClick={() => {
                      handleClose()
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === 'Escape') {
                        e.preventDefault();
                        handleClose();
                        buttonRef.current?.focus();
                        return;
                      }
                      if (index === 0 && e.shiftKey) {
                        e.preventDefault()
                        handleClose()
                        buttonRef.current?.focus();
                        return;
                      }
                      if (e.key === 'Tab') {
                        // Only handle special case for last item
                        if (index === subCategories.length - 1 && !e.shiftKey) {
                          e.preventDefault();
                          handleClose();
                          buttonRef.current?.focus();
                        }
                        // Remove any other Tab key handling to allow natural tab behavior
                      } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        // Keep default MUI arrow key behavior
                        return;
                      }
                    }}
                    role={"link"}
                  >
                    {subTitle}

                  </StyledMenuItem>
                </Link>
              </StyledLi>
            )
          })}
        </StyledMenu>
      </StyledDiv>
    )
  }
  return (
    <StyledLink
      onClick={onClick}
      selected={selected}
      color={color}
      isLast={isLast}
    >
      <OutlineLink
        href={href}
        target={blank ? "_blank" : "_self"}
        style={{ textDecoration: "none" }}
      >
        <NewText
          component={"p"}
          largeDesktopFontSize={18}
          largeDesktopLineHeight="20px"
          desktopFontSize={14}
          desktopFontWeight={400}
        >
          {title}
        </NewText>
      </OutlineLink>
    </StyledLink>
  )
}

export default DesktopNavlink
