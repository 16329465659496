import styled from "@emotion/styled";
import { Backdrop, Drawer, Link } from "@mui/material";
import { FetchMenusQuery } from "apollo";
import MobileHeaderCustom from "chargeLayout/MobileHeaderCustom";
import { AppType, useAppType } from "contexts/AppTypeProvider";
import { useLocale } from "next-intl";
import NextLink from "next/link";
import { usePathname } from "next/navigation";
import { trackTopBarLinkClicked } from "reusable/ChargeGTM";
import NavLink from "ui/NavLink/NavLink";
import { sharedColors } from "ui/theme/colors";
import { extractMenuProperties } from "utils";
import { getNonUmbracoURL } from "utils/utils";

const StyledMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
`;
const StyledMenuSwitcher = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-right: 20px;
  margin-bottom: 16px;
`;
const Divider = styled.div`
  height: 100%;
  width: 1px;
  background-color: gray;
  height: 20px;
`;
export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
`;
export const StyledList = styled.ul<{ withButton?: boolean }>`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: relative;
   padding-bottom: ${(props) => (props.withButton ? "0px" : "22px")};
   ${(props) => props.withButton && `
    margin-bottom: 0px;
  `}
  height: auto;
  max-height: 520px;
  overflow-y: auto;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
    padding: 0px;
  }
`;
export const StyledLinkHeb = styled(Link) <{ selected: boolean }>`
  text-decoration: none;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "500" : "400")};
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.black};
  border-bottom: ${(props) => (props.selected ? "2px solid black" : "")};
`;

export const StyledLinkEn = styled(Link) <{ selected: boolean }>`
  position: relative;
  top: 1px;
  text-decoration: none;

  font-style: normal;
  font-weight: ${(props) => (props.selected ? "900" : "400")};
  font-size: 14px;
  line-height: 20px;
  border-bottom: ${(props) => (props.selected ? "2px solid black" : "")};
  color: ${(props) => props.theme.colors.black};
`;
export const StyledLink = styled(NextLink) <{ selected?: boolean }>`
  display: flex;
`;
export const StyledImageAndName = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  padding: 0px 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 0px;
  }
`;

const StyledMapChargedButton = styled.div`
  width: 100%;
  height: 51px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  padding: 12px;
  padding-left: 20px;
  color: white;
  background-color: ${sharedColors.blue};
`;
const StyledLinkButton = styled(Link)`
  color: white;
  text-decoration: none;
`;
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  align-items: center;
  padding: 0;
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 0 60px;
  }
`
const StyledWrapperButton = styled(StyledButton)`
    position: absolute;
    top: 5px;
    left: -5px;
`

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean;
  menuItems: FetchMenusQuery;
  hrefValue: string;
  showSelectLanguage?: boolean;
  withButtonAtEnd?: boolean;
  onClickButtonAtEnd?: () => void;
};

const SideMenu = ({
  menuItems,
  open,
  setOpen,
  showSelectLanguage = true,
  withButtonAtEnd = false,
  onClickButtonAtEnd,
}: Props) => {
  const locale = useLocale();
  const pathname = usePathname();
  let hrefValue = "";
  let hrefHeValue = "";
  if (pathname.includes("investor-relations")) {
    hrefValue = "/en/investor-relations";
    hrefHeValue = "/investor-relations";
  } else {
    hrefValue = "/en/about-us";
    hrefHeValue = "/";
  }

  const handleNavLinkCliked = (title: string) => {
    setOpen(false);
    trackTopBarLinkClicked(title);
  };
  const { appType } = useAppType()
  return (
    <>
      <Drawer
        anchor="top"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            display: "flex",
            flexDirection: "column",
            borderEndStartRadius: 20,
            borderEndEndRadius: 20,
            backgroundColor: "#fff",
          },
          role: "navigation",
        }}
      >
        <MobileHeaderCustom
          open={open}
          setOpen={setOpen}
          href={appType === AppType.Corporate ? "/" : "/pazcharge"}
          logo={appType === AppType.Corporate ? "/Logo/mobile.svg" : "/icons/pazCharge/Logo/Logo.svg"} />
        <StyledMenuHeader></StyledMenuHeader>
        {showSelectLanguage && (
          <StyledMenuSwitcher>
            <StyledLinkHeb
              selected={locale === "he"}
              href={hrefHeValue}
              aria-label={locale === "en" ? "לאתר בעברית" : "לך לעברית"}
            >
              עב
            </StyledLinkHeb>
            <Divider />
            <StyledLinkEn
              selected={locale === "en"}
              href={hrefValue}
              aria-label={locale === "en" ? "לאתר באנגלית" : "לך לאנגלית"}
            >
              Eng
            </StyledLinkEn>
          </StyledMenuSwitcher>
        )}

        <StyledList withButton={withButtonAtEnd}>
          {menuItems?.menu_desktop?.nodes?.map((item: any, index: number) => {
            const { title, url } = extractMenuProperties(item);
            return (
              <NavLink
                onClick={() => handleNavLinkCliked(title)}
                key={index.toString()}
                href={getNonUmbracoURL(url)}
                selected={decodeURI(pathname) + "/" === url}
                title={title}
                subCategories={item?.children}
                isLast={index === menuItems?.menu_desktop?.nodes?.length! - 1}
              />
            );
          })}
        </StyledList>
        {withButtonAtEnd && (
          <StyledMapChargedButton onClick={onClickButtonAtEnd}>
            <StyledLinkButton href={"/pazcharge/charging-stations-map"}>
              מפת עמדות טעינה
            </StyledLinkButton>
          </StyledMapChargedButton>
        )}
      </Drawer>
      <Backdrop
        open={open}
        sx={{ color: "#fff", zIndex: -1 }}
        onClick={() => setOpen(false)}
      />
    </>
  );
};

export default SideMenu;
