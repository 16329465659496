import styled from "@emotion/styled";
import { AppType, useAppType } from "contexts/AppTypeProvider";
import { StyledLink } from "layout/SideMenu";
import Image from "next/image";

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
`;
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  align-items: center;
  padding: 0;
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 0 60px;
  }
`
const StyledWrapperButton = styled(StyledButton)`
    position: absolute;
    top: 5px;
    left: -5px;
`
const StyledHeader = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 100001;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  height: 64px;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
  box-shadow: ${(props) =>
    !props.isOpen ? "0px 2px 8px 0px rgba(130, 136, 148, 0.25)" : null};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 98px;
    padding: 0;
  }
`
type MobileHeaderChargeProps = {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  logo: string,
  href: string
}
const headerDataConfig = {
  [AppType.Corporate]:{
    logo: "/Logo/mobile.svg",
    href: "/"
  },
  [AppType.Charge]:{
    logo: "/icons/pazCharge/Logo/Logo.svg",
    href: "/pazcharge"
  }
}

function MobileHeaderCustom({ open, setOpen, href, logo }: MobileHeaderChargeProps) {
  const {appType} = useAppType()
  return (
    <StyledHeader isOpen={open}>
      <StyledContainer>
        <StyledWrapperButton
          aria-expanded={open ? true : false}
          aria-label={open ? "סגירת תפריט" : "פתיחת תפריט"}
          onClick={() => setOpen((prev) => !prev)}
        >
          <Image
            src={open ? "/icons/close.svg" : "/icons/burger.svg"}
            alt="burger"
            width={24}
            height={24}
            priority
          />
        </StyledWrapperButton>
        <StyledLink
          href={href}
        >
          <Image
            src={logo}
            alt="Paz Charge, to the home page"
            width={138}
            height={40}
            priority
          />
        </StyledLink>
      </StyledContainer>
    </StyledHeader>
  )
}
export default MobileHeaderCustom
