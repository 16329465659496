import { HttpLink, useApolloClient } from "@apollo/client"
import axios from "api/api/axios"
import { prodSearchPath, stagingSearchPath } from "lib/searchPath"
import { ReactNode, createContext, useEffect, useState } from "react"

interface ContextType {
  GRAPHQL_URL: string
  API_URL: string
  ENV: "staging" | "production"
  searchPath: string
  isApiReady?: boolean
}

// Initialize the context with a default value
const defaultState: ContextType = {
  GRAPHQL_URL: process.env.GRAPHQL_URL || "",
  API_URL: process.env.API_URL || "",
  ENV: "production",
  searchPath: "",
  isApiReady: false,
}

export const LayoutContext = createContext<ContextType>(defaultState)

interface Props {
  children: ReactNode
}

export const LayoutProvider: React.FC<Props> = ({ children }) => {
  const [GRAPHQL_URL, setGRAPHQL_URL] = useState<string>(
    process.env.GRAPHQL_URL || "",
  )
  const [API_URL, setAPI_URL] = useState<string>(process.env.API_URL || "")
  const [ENV, setENV] = useState<"staging" | "production">("production")
  const [searchPath, setSearchPath] = useState<string>("")
  const [isApiReady, setIsApiReady] = useState<boolean>(false)

  const apolloClient = useApolloClient()

  useEffect(() => {
    const env = GRAPHQL_URL.includes("qa") ? "staging" : "production"
    setENV(env)
    setSearchPath(env === "production" ? prodSearchPath : stagingSearchPath)
  }, [GRAPHQL_URL])

  useEffect(() => {
    fetch("/api/settings")
      .then((response) => response.json())
      .then((data) => {
        axios.defaults.baseURL = data.apiUrl
        setAPI_URL(data.apiUrl)
        setGRAPHQL_URL(data.graphqlUrl)
        apolloClient.link = new HttpLink({
          uri: GRAPHQL_URL,
        })
        setIsApiReady(true)
      })
  }, [])

  return (
    <LayoutContext.Provider
      value={{
        GRAPHQL_URL,
        API_URL,
        ENV,
        searchPath,
        isApiReady
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
