import React, { createContext, useContext, useState, ReactNode } from 'react';

export enum AppType {
  Charge = 'Charge',
  Corporate = 'Corporate',
}

interface AppTypeContextProps {
  appType: AppType;
}

const AppTypeContext = createContext<AppTypeContextProps | undefined>(undefined);

interface AppTypeProviderProps {
  children: ReactNode;
  initialAppType: AppType;
}

export const AppTypeProvider = ({ children, initialAppType }: AppTypeProviderProps) => {
  const [appType] = useState<AppType>(initialAppType || AppType.Corporate);

  return (
    <AppTypeContext.Provider value={{ appType }}>
      {children}
    </AppTypeContext.Provider>
  );
};

// Custom hook to use the AppType context
export const useAppType = (): AppTypeContextProps => {
  const context = useContext(AppTypeContext);
  if (!context) {
    throw new Error('useAppType must be used within an AppTypeProvider');
  }
  return context;
};
